<template>
  <div class="content md flex-box vertical">
    <div class="data-title">
      <div class="title-left flex-box">
        <span>共{{ pagination.total }}条记录</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入志工姓名查询" allow-clear></a-input>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="updateList">查询</a-button>
        <!-- <a-button type="primary" @click="add" v-if="active == 2">添加人员</a-button> -->
        <a-button type="primary" @click="exportData" :loading="exporting">
          {{ exporting ? "正在导出" : "批量导出" }}
        </a-button>
        <a-dropdown :disabled="!selectedRowKeys.length" v-if="active == 1">
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item key="2">
              确认发放
            </a-menu-item>
            <a-menu-item key="3">
              不发放
            </a-menu-item>
          </a-menu>
          <a-button type="primary">一键处理</a-button>
        </a-dropdown>
      </a-space>
    </div>
    <div class="data-tabs">
      <a-tabs v-model="active">
        <a-tab-pane :key="tab.value" :tab="tab.label" v-for="tab in catList"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :row-selection="rowSelection"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ y: h }"
        @change="handleTableChange"
      >
        <template v-slot:action="text, record">
          <div class="row-btn">
            <template v-if="active == 1">
              <a class="txt-btn" @click.stop="grantRow(record, true)">确认发放</a>
              <a class="txt-btn" @click.stop="grantRow(record, false)">不发放</a>
            </template>
            <a class="txt-btn danger" @click.stop="delRow(record)" v-if="active == 2">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="400"
      :confirm-loading="confirmLoading"
      title="添加人员"
      ok-text="确认"
      cancel-text="取消"
      @ok="confirm"
    >
      <a-form-model class="item-form" ref="editForm" :model="single" :rules="rules" layout="vertical" labelAlign="left">
        <a-form-model-item label="共修手册发放日期" prop="date">
          <a-date-picker
            v-model="single.date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="人员" prop="list">
          <select-entity
            v-model="single.list"
            btnText="添加人员"
            type="volunteer"
            custom-query="&sort=-id&expand=volunteerGroup"
            multiple
          ></select-entity>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { volunStatusOptions, unverifiedOptions } from "../../../common/hr/volunteer";
import { clone, downLoadBlobFile, getKeyTitle, getQuery, getTempleId } from "../../../common/js/tool";

export default {
  name: "ManualList",
  data() {
    return {
      url: "/admin/volunteer-examine-middle",
      loading: false,
      list: [],
      form: {},
      h: 500,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      active: 1,
      catList: [
        {
          label: "待发放",
          value: 1,
        },
        {
          label: "已发放",
          value: 2,
        },
      ],
      groupOptions: [],
      selectedRowKeys: [],
      searchKeyType: {
        name: 2,
      },
      exporting: false,
      visible: false,
      confirmLoading: false,
      single: {},
      rules: {
        date: [{ required: true, message: "请选择共修手册发放日期", trigger: "change" }],
        list: [{ required: true, message: "请选择人员", trigger: "change" }],
      },
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          title: "姓名",
          customRender: (text) => {
            return <span>{text?.volunteer?.name || "-"}</span>;
          },
        },
        {
          title: "身份证号",
          customRender: (text) => {
            return <span>{text?.volunteer?.id_card || "-"}</span>;
          },
        },
        {
          title: "注册小组",
          customRender: (text) => {
            return <span>{text?.volunteer?.volunteerGroup?.name || "-"}</span>;
          },
        },
        {
          title: "注册小组志工状态",
          customRender: (text) => {
            return (
              <span>{getKeyTitle(volunStatusOptions, text?.volunteer?.register_status, "value", "label") || "-"}</span>
            );
          },
        },
      ];
      if (this.active == 1) {
        columns.push({
          title: "共修手册发放状态",
          dataIndex: "grant_status",
          customRender: (text) => {
            return <a-tag>{getKeyTitle(unverifiedOptions, text, "value", "label") || "-"}</a-tag>;
          },
        });
      } else if (this.active == 2) {
        columns.push({ title: "共修手册发放日期", dataIndex: "update_time" });
      }
      columns.push({
        title: "操作",
        key: "action",
        fixed: "right",
        scopedSlots: { customRender: "action" },
        width: 140,
      });
      return columns;
    },
    rowSelection() {
      return this.active == 1
        ? { selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange, fixed: true }
        : null;
    },
  },
  watch: {
    active() {
      this.selectedRowKeys = [];
      this.updateList();
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    dealList(list) {
      return list;
    },
    getList() {
      this.loading = true;
      let active = this.active;
      let url = `${this.url}/list?status=${active}`; // manual筛选共修手册is_fixed=1
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      if (this.active == 1) {
        url += "&grant_status=1"; // 查 grant_status 1或2
      }
      url += getTempleId();
      url += getQuery.call(this, this.searchKeyType);
      this.$axios
        .get(url, { noTempleFilter: true })
        .then((res) => {
          this.loading = false;
          let list = this.dealList(res.data);
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      const searchKeyType = {
        name: 2,
      };
      let url = `/admin/volunteer-examine-middle/export?status=${this.active}`;
      url += getTempleId();
      url += getQuery.call(this, searchKeyType);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          const target = this.catList.find((item) => item.value == this.active);
          downLoadBlobFile(res, `${target.label}数据`);
        })
        .finally(() => {
          this.exporting = false;
        });
    },
    add() {
      this.visible = true;
    },
    confirm() {
      this.confirmLoading = true;
      this.$refs.editForm.validate((flag) => {
        if (flag) {
          let form = clone(this.single);
          this.$axios({
            url: `/admin/xxx`,
            method: "POST",
            data: form,
          }).then(() => {
            this.getList();
            this.confirmLoading = false;
            this.$message.success("编辑成功");
            this.visible = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
    grantRow(record, flag) {
      let text = "不发放";
      let params = { grant_status: 3 };
      if (flag) {
        // 发放
        text = "发放";
        params = { status: 2 };
      }
      this.$confirm({
        title: "提示",
        content: `确定${text}此手册吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${record.id}`,
            method: "PATCH",
            data: params,
          }).then(() => {
            this.$message.success("操作成功!");
            this.getList();
          });
        },
      });
    },
    delRow(record) {
      this.$confirm({
        title: "提示",
        content: `确定删除此志工吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${record.id}`,
            method: "DELETE",
          }).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        },
      });
    },
    handleMenuClick(e) {
      let ids = this.selectedRowKeys;
      if (ids.length) {
        let status = e.key;
        this.$axios({
          url: `/admin/volunteer-examine-middle/batch-updates`,
          method: "POST",
          data: {
            ids: ids.join(","),
            status,
          },
        }).then(() => {
          this.$message.success("操作成功");
          this.getList();
        });
      } else {
        this.$message.error(`请选择至少一个数据`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.data-tabs {
  text-align: center;
}
.data-area {
  margin-top: 0;
}
</style>
